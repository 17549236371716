.home {
	text-align: center;
	max-width: 800px;
	padding: 40px 30px;
	margin: 0 auto;

	img {
		margin: 1.5rem auto 1.5rem;
	}

	> :last-child {
		margin-bottom: 0;
	}

	&-container {
		display: flex;
		flex-direction: column;
		min-height: 100vh;
		justify-content: center;

		@include mq($headerSwap) {
			min-height: auto;
			display: block;
			padding-top: 25px;

			h1 {
				margin-bottom: 1.625rem;
			}

			p {
				font-size: 18px;
			}
		}
	}
}


.splash-overlay {
	background: #222;
	text-align: center;
	color: #FFF;
	position: fixed;
	height: 100vh;
	width: 100vw;
	padding: 2rem;
	left: 0;
	top: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	font-size: 13px;
	z-index: 300;

	img {
		max-width: 160px;
		margin: 0 auto 1.25rem;
	}

	p {
		font-size: 1em;
		color: #999;
		max-width: 175px;
		margin: 0 auto 2.2rem;

		&:last-child {
			margin: 5.125rem auto 0;
		}
	}

	a {
		font: 700 1em $heading;
	}
}
