.contact {
	background: rgba(0,0,0,0.71);
	position: fixed;
	z-index: 100;
	height: 100%;
	width: 100%;
	left: 0;
	top: 0;

	&__content {
		background: #FFF;
		width: calc(100% - 10px);
		position: absolute;
		transform: translate3d(-50%, -50%, 0);
		border-radius: 15px;
		max-width: 500px;
		text-align: center;
		padding: 32px 0 0;
		left: 50%;
		top: 50%;

		p {
			line-height: 21px;
			margin: 0 0 8px;
		}

		h3 {
			margin-bottom: 18px;
		}
	}

	[href^="http"] {
		color: $red;
	}

	&__close {
		border-top: 1px solid #F5F5F5;
		padding: 20px 10px;
		display: block;
		font: 700 13px $heading;
		letter-spacing: 2px;
		text-transform: uppercase;
		margin: 1.5rem 0 0;
		cursor: pointer;
		color: #CCC;
	}

	&__close-overlay {
		position: absolute;
		height: 100%;
		width: 100%;
		left: 0;
		top: 0;
	}
}