.fade-enter-active,
.fade-leave-active {
	transition: opacity .2s ease;
}

.fade-enter, .fade-leave-active {
	opacity: 0
}

.contact.fade-enter-active,
.contact.fade-leave-active {
	transition: opacity .4s ease;
}

.slide-left-enter,
.slide-right-leave-active {
	transform: translate(100%, 0);
	z-index: 4;
}

.slide-left-leave-active,
.slide-right-enter {
	transform: translate(-100%, 0);
	z-index: 4;
}

@include mq(40em) {
	.slide-left-enter,
	.slide-right-leave-active {
		opacity: 0;
		transform: none;
	}

	.slide-left-leave-active,
	.slide-right-enter {
		opacity: 0;
		transform: none;
	}
}