.video {
	padding: 0 6px;
	max-width: 950px;
	margin: 0 auto;

	h1 {
		font-size: 24px;
	}

	&__content {
		padding: 16px 7px;
	}

	p {
		max-width: 550px;
	}

	video {
		width: 100%;
		height: auto;
		margin: 0;
		display: block;

		&.playing ~ .video-controls {
			opacity: 0.2;
			transition-delay: 1s;
		}

		&.loading ~ .loader {
			opacity: 1;
			visibility: visible;
		}

		&.pausing ~ .pauser {
			opacity: 1;
			visibility: visible;
		}
	}
}

.video-container {
	position: relative;
	background: #1A1A1A;
}

.video-controls {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 46px;
	background: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.5));
	transition: 300ms opacity;

	.hide-controls & {
		display: none;
	}

	div {
		max-width: 375px;
		margin: 0 auto;
		position: relative;
		height: 46px;
	}

	&:hover {
		opacity: 1!important;
		transition-delay: 0s!important;
	}

	button {
		background: none;
		border: none;
		outline: none;
		box-shadow: none;
		padding: 0;
		width: 30px;
		height: 30px;
		text-indent: 200%;
		white-space: nowrap;
		overflow: hidden;
		background-repeat: no-repeat;
		background-image: url('/static/images/sprites.png');
		background-size: 19px 164px;
		transition: 200ms background-position;
	}
	
	.time,
	button,
	[type="range"] {
		transform: translateY(-50%);
		position: absolute;
		top: 50%;
	}

	#play-button {
		background-position: center 11px;
		width: 41px;
		left: 5px;

		&.playing {
			background-position: center -18px;
		}
	}

	#mute-button {
		background-position: center -115px;
		right: 45px;

		&.muted {
			background-position: center -143px;
		}
	}

	#fullscreen-button {
		background-position: center -50px;
		right: 9px;

		&.fullscreen {
			background-position: center -83px;
		}
	}

	#seek {
		width: calc(100% - 180px);
		left: 43px;
	}

	.time {
		right: 85px;
		font: 700 12px $heading;
		color: #FFF;
		margin: 1px 0 0;
	}


	input[type=range] {
	  -webkit-appearance: none;
	  width: 100%;
	  margin: 2.975px 0;
	  background-color: transparent;
	  background-image: -webkit-gradient(
          linear,
          left top,
          right top,
          color-stop(0, $red),
          color-stop(0, rgba(255, 255, 255, 0.5))
      );
	}
	input[type=range]:focus {
	  outline: none;
	}
	input[type=range]::-webkit-slider-runnable-track {
	  width: 100%;
	  height: 6.3px;
	  cursor: pointer;
	  box-shadow: none;
	  background: rgba(255, 255, 255, 0);
	  border-radius: 0px;
	  border: none;
	}
	input[type=range]::-webkit-slider-thumb {
	  box-shadow: none;
	  border: 1px solid rgba(0, 0, 0, 0);
	  height: 12.25px;
	  width: 12.25px;
	  border-radius: 50px;
	  background: #ffffff;
	  cursor: pointer;
	  -webkit-appearance: none;
	  margin-top: -2.975px;
	}

	input[type=range]::-moz-range-track {
	  width: 100%;
	  height: 6.3px;
	  cursor: pointer;
	  box-shadow: none;
	  background: rgba(255, 255, 255, 0);
	  border-radius: 0px;
	  border: none;
	}
	input[type=range]::-moz-range-thumb {
	  box-shadow: none;
	  border: 1px solid rgba(0, 0, 0, 0);
	  height: 12.25px;
	  width: 12.25px;
	  border-radius: 50px;
	  background: #ffffff;
	  cursor: pointer;
	}
	input[type=range]::-ms-track {
	  width: 100%;
	  height: 6.3px;
	  cursor: pointer;
	  background: transparent;
	  border-color: transparent;
	  color: transparent;
	}
	input[type=range]::-ms-fill-lower {
	  background: $red;
	  border: none;
	}
	input[type=range]::-ms-fill-upper {
	  background: rgba(255, 255, 255, 0.5);
	  border: none;
	  border-radius: 0px;
	  box-shadow: none;
	}
	input[type=range]::-ms-thumb {
	  box-shadow: none;
	  border: 1px solid rgba(0, 0, 0, 0);
	  height: 12.25px;
	  width: 12.25px;
	  border-radius: 50px;
	  background: #ffffff;
	  cursor: pointer;
	  height: 6.3px;
	}

}

.video-container:-moz-full-screen {
	width: 100vw;
	height: 100vh;

	video {
		width: 100%;
		height: 100%;
		object-fit: contain;
		object-position: center;
		font-family: 'object-fit: contain; object-position: center;';
	}
}

.video-container:-ms-fullscreen {
	width: 100vw;
	height: 100vh;

	video {
		width: 100%;
		height: 100%;
		object-fit: contain;
		object-position: center;
		font-family: 'object-fit: contain; object-position: center;';
	}
}

.video-container:webkit-full-screen {
	width: 100vw;
	height: 100vh;

	video {
		width: 100%;
		height: 100%;
		object-fit: contain;
		object-position: center;
		font-family: 'object-fit: contain; object-position: center;';
	}
}

.video-container:fullscreen {
	width: 100vw;
	height: 100vh;

	video {
		width: 100%;
		height: 100%;
		object-fit: contain;
		object-position: center;
		font-family: 'object-fit: contain; object-position: center;';
	}
}


.pauser,
.loader {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate3d(-50%, -50%, 0);
	width: 40px;
	height: 40px;
	z-index: 3;
	transition: 300ms visibility, 300ms opacity;
	opacity: 0;
	visibility: visible;

	&:after {
		background: url('/static/images/loader.png') center /40px no-repeat;
		animation: 2s spin infinite linear;
		position: absolute;
		content: '';
		height: 40px;
		width: 40px;
		left: 0;
		top: 0;
	}
}

.pauser:after {
	animation: none;
	background: url('/static/images/pauser.png') center /40px no-repeat;
}

@keyframes spin {
	0% {
		transform: rotate(0deg)
	}

	100% {
		transform: rotate(360deg)
	}
}


*::-webkit-media-controls-panel {
  display: none!important;
  -webkit-appearance: none;
}

*::--webkit-media-controls-play-button {
  display: none!important;
  -webkit-appearance: none;
}

*::-webkit-media-controls-start-playback-button {
  display: none!important;
  -webkit-appearance: none;
}