h1,
h2,
h3,
h4 {
	margin: 0 0 1rem;
	color: #4A4A4A;
	font: 700 40px 'Roboto', Helvetica, sans-serif;
}

h2,
h3 {
	font-size: 20px;
}

h4 {
	font-size: 12px;
}

p {
	font-size: 15px;
	margin: 0 0 1rem;
}

.center {
	text-align: center;
}

a {
	color: inherit;
	text-decoration: none;
}